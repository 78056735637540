<template>
  <el-dialog :visible.sync="dialog" :title="title" append-to-body :close-on-click-modal="false" @closed="cancel" width="640px">
    <template v-if="purchaseOrder">
      <div v-if="loading">
        <i class="el-icon-loading"></i>
        &nbsp;正在查找采购单【{{purchaseOrder.formCode}}】对应的发货通知单，请稍候…
      </div>
      <template v-else-if="data && data.length">
        <div></div>
      </template>
      <template v-else>
        <div>没有找到采购单【{{purchaseOrder.formCode}}】对应的发货信息。</div>
        <div slot="footer">
          <el-button @click="dialog = false">关闭</el-button>
        </div>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  data() {
    return {
      dialog: false,
      purchaseOrder: null
    };
  },
  computed: {
    title() {
      return this.loading ? "正在查找发货通知单" : "选择发货通知单";
    }
  },
  methods: {
    cancel() {},
    beforeInit() {
      this.url = `api/purchaseForm/${this.purchaseOrder.id}/deliver`;
      return true;
    },
    resetForm(order) {
      if (order && order.id) {
        this.purchaseOrder = order;
        this.toQuery();
        this.dialog = true;
      }
    }
  }
};
</script>